
































































import {Component, Mixins} from "vue-property-decorator";
import XForm from "@/components/SimpleForm.vue";
import { paymentNoAuthForm } from "@/assets/scripts/form-fields/main";
import {validationMixin} from "vuelidate";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import VueRecaptcha from 'vue-recaptcha';
import XButton from "../SimpleButton.vue";

const { values, attrs, validations, errors } = paymentNoAuthForm;

@Component({
  components: {
    XForm,
    XButton,
    VueRecaptcha,
  },
  computed: {
    ...errors,
  },
  mixins: [validationMixin],
  validations,
})
class PaymentNoAuthCard extends Mixins(AppApiMixin, XForm) {
  [x: string]: any

  values = values
  defaultValues: {[x: string]: any} = {}
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  submitSuccess = "";
  submitError = "";

  siteKey = ''
  recaptchaEnabled = false;

  paymentFee = 0;

  mounted() {
    this.siteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY || ''
    this.recaptchaEnabled = (process.env.VUE_APP_RECAPTCHA_ENABLED || '') === "true"

    this.values = {...this.values, ...this.$attrs};
    this.defaultValues = {...this.values, ...this.$attrs};

    this.paymentFee = this.getPaymentFee()
  }

  public onCaptchaExpired () {
    //@ts-ignore
    if (this.recaptchaEnabled) {
      this.$refs.recaptcha.reset()
    }
  }

  public validate() {
    //@ts-ignore
    if (this.recaptchaEnabled) {
      this.$refs.recaptcha.execute()
    } else {
      this.submitForm()
    }
  }

  async submitForm() {
    this.useSuccessAlert = false
    this.useSubmitButtonLoading = true

    const formData = new FormData
    for (let key in this.values) {
      formData.append(key, this.values[key]);
    }

    try {
      const data = await this.getPaymentNoAuthURL(formData)

      if(data && data.result && (data.url) != 0) {
        window.location.href = data.url;
      }
    } catch (e) {
      console.log('error', e);
      this.submitError = e.data;
    }
    this.submitSuccess = '';
    this.useSubmitButtonLoading = false
  }

  changeAmount() {
    this.values['total'] = this.values['amount'] + (this.values['amount'] * (+this.paymentFee || 0));
  }
}

export default PaymentNoAuthCard;
