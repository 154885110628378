













import {Component, Vue} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import PaymentNoAuthForm from "@/components/for-payments-view/PaymentNoAuthForm.vue";
import PaymentHelpCard from "@/components/for-payments-view/PaymentHelpCard.vue";
import {paymentNoAuthForm} from "@/assets/scripts/form-fields/main";

const { values } = paymentNoAuthForm;

@Component({
  components: { Page, PaymentNoAuthForm, PaymentHelpCard },
})
class PaymentsNoAuth extends Vue {
  [x: string]: any
  values = values

  mounted() {
    this.values = {...this.values};
    this.defaultValues = {...this.values};
  }
}

export default PaymentsNoAuth;
